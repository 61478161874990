import './modules/swiper.js';
import './modules/nav.js';
import './modules/catalog-sidebar.js';
import './modules/custom-select.js';
import './modules/profile.js';
import './modules/fancybox.js';
import './modules/msnry.js';

//import './modules/favourite-btn.js';
//import './modules/catalog-item-text-split.js';
//import './modules/yMaps.js';
//import './modules/order.js';
